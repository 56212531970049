import React, { useState, useEffect, useMemo } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import BlogListingHero from '../components/pageComponents/blogListingHero'
import BlogListingWithFilter from '../components/pageComponents/blogListingWithFilters'
import BlogFilter from '../components/pageComponents/blogFilter'
import Wrapper from '../components/wrapper'
import { strapiURL } from '../config'
import { ARTICLE_CATEGORY_ALL } from '../data'
import FeaturedJob from '../components/pageComponents/featuredJob'
import BlogADBlock from '../components/pageComponents/blogADBlock'
import BlogLatest from '../components/pageComponents/blogLatest'
import BlogPopular from '../components/pageComponents/blogPopular'

const Faqpage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const category = params.get('category') || ARTICLE_CATEGORY_ALL
  const keyword = params.get('keyword') || ''

  const [articles, setArticles] = useState([])

  const [loading, setLoading] = useState(true)

  const [articlePaginationInfo, setArticlePaginationInfo] = useState({
    totalCount: 0,
    totalPage: 0,
    perPageCount: 0,
  })
  const [articlePageIndex, setArticlePageIndex] = useState(0)

  const [industryArticles, setIndustryArticles] = useState([])
  const [industryArticlePageIndex, setIndustryArticlePageIndex] = useState(0)
  const [industryArticlePaginationInfo, setIndustryArticlePaginationInfo] =
    useState({
      totalCount: 0,
      totalPage: 0,
      perPageCount: 0,
    })

  let title = 'Medical Sales Career Advice | MedReps'
  let description =
    'Discover expert tips, insights, and strategies to advance your career in the medical sales industry. MedReps will allow you to take the next step towards success with our comprehensive career advice resources for medical sales professionals.'

  if (category && category !== ARTICLE_CATEGORY_ALL) {
    if (category == 'Job Search') {
      title = 'Job Search Career Advice | MedReps'
      description =
        "Whether you're a recent graduate, a seasoned professional or looking to make a career change, our Job Search Career Advice page offers expert tips, strategies and insights to help you navigate the job market effectively."
    } else if (category == 'Breaking Into Medical Sales') {
      title = 'Breaking into Medical Sales Career Advice | MedReps'
      description =
        'MedReps offers expert guidance on how to navigate the industry, develop relevant skills and land your first job in medical sales.'
    } else if (category == 'Sales Advice') {
      title = 'Sales Advice Career Advice | MedReps'
      description =
        "Looking to boost your medical sales career? MedReps is your go-to resource for tips and strategies. Whether you're new to sales or looking to climb the corporate ladder, our page offers invaluable advice to help you excel."
    } else if (category == 'Insider') {
      title = 'Insider Career Advice | MedReps'
      description =
        "Expand your knowledge and skills in the field of medical sales with our comprehensive medical sales career advice. Designed for aspiring medical sales professionals or those looking to advance their careers, you'll find specialized insights into the industry."
    } else if (category == 'Industry') {
      title = 'Industry Career Advice| MedReps'
      description =
        'Stay up-to-date with the latest news and trends in the medical sales industry.'
    }
  }

  useEffect(() => {
    loadIndustryArticles(industryArticlePageIndex)
  }, [industryArticlePageIndex])

  const loadIndustryArticles = async (page = 0) => {
    try {
      const result = await axios.post(strapiURL + '/articles/searchIndex', {
        category: 'Industry', // Need to check with prod
        page,
      })

      if (page > 0) {
        const updatedArticles = industryArticles.concat(result.data?.hits || [])
        setIndustryArticles(updatedArticles)
      } else {
        setIndustryArticles(result.data?.hits || [])
      }

      setIndustryArticlePaginationInfo({
        totalCount: result.data?.nbHits,
        totalPage: result.data?.nbPages,
        perPageCount: result.data?.hitsPerPage,
      })
    } catch (error) {
      console.log('error --- ', error)
    }
  }

  const handleViewMoreIndustryArticle = () => {
    setIndustryArticlePageIndex(prev => prev + 1)
  }

  useEffect(() => {
    loadArticles(category, keyword, articlePageIndex)
  }, [category, keyword, articlePageIndex])

  const loadArticles = async (category, keyword, page) => {
    try {
      const filter = {
        category: category !== ARTICLE_CATEGORY_ALL ? category : '-Industry',
        keyword,
        page,
      }
      const result = await axios.post(
        strapiURL + '/articles/searchIndex',
        filter
      )
      if (page > 0) {
        const updatedArticles = articles.concat(result.data?.hits || [])
        setArticles(updatedArticles)
      } else {
        setArticles(result.data?.hits || [])
      }
      setArticlePaginationInfo({
        totalCount: result.data?.nbHits,
        totalPage: result.data?.nbPages,
        perPageCount: result.data?.hitsPerPage,
      })
      setLoading(false)
    } catch (error) {
      console.log('error --- ', error)
    }
  }

  const handleViewMoreArticle = () => {
    setArticlePageIndex(prev => prev + 1)
  }

  const showArticleMore = useMemo(
    () => articlePaginationInfo.totalPage > articlePageIndex + 1,
    [articlePageIndex, articlePaginationInfo]
  )

  const handleCategory = val => {
    setArticlePageIndex(0)
    const params = new URLSearchParams({
      category: val,
      keyword,
    })
    navigate(`/medical-sales-careers/?${params}`)
  }

  const handleKeyword = val => {
    setArticlePageIndex(0)
    const params = new URLSearchParams({
      category,
      keyword: val,
    })
    navigate(`/medical-sales-careers/?${params}`)
  }

  return (
    <Wrapper location={location} title={title} description={description}>
      <div className="py-0 lg:py-12 container mx-auto">
        {/* <BlogListingHero
          featuredArticles={featuredArticles}
          loading={loading}
        /> */}
        <div className="block lg:hidden">
          <BlogFilter
            activeCategory={category}
            keyword={keyword}
            onChangeCategory={handleCategory}
            onChangeKeyword={handleKeyword}
          />
        </div>
        <div className="block lg:flex">
          <div className="pr-0 mr-0 border-0 lg:pr-6 lg:mr-6 lg:border-r border-black flex-[3]">
            <BlogListingWithFilter
              articles={articles}
              industryArticles={industryArticles}
              loading={loading}
              showArticleMore={showArticleMore}
              industryArticlePageIndex={industryArticlePageIndex}
              industryArticlePaginationInfo={industryArticlePaginationInfo}
              activeCategory={category}
              handleViewMoreArticle={handleViewMoreArticle}
              handleViewMoreIndustryArticle={handleViewMoreIndustryArticle}
            />
          </div>

          <div className="flex-1">
            <div className="hidden lg:block">
              <BlogFilter
                activeCategory={category}
                keyword={keyword}
                onChangeCategory={handleCategory}
                onChangeKeyword={handleKeyword}
              />
            </div>
            <FeaturedJob />
            <BlogADBlock id={'article_list'} />
            <BlogLatest />
          </div>
        </div>
        <BlogPopular />
      </div>
    </Wrapper>
  )
}
export default Faqpage
